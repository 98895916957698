<template>
    <component :is="template">
        <template v-if="ready">
            <v-responsive v-if="ready && giftcard" width="100%" class="pb-4" v-intersect="{ handler: onIntersect, options: { threshold: [0, 1.0] } }">
                <v-card tile elevation="0">
                    <v-card-text class="body-1">
                        <v-row>
                            <!-- 상품 이미지 -->
                            <v-col cols="12" md="7" class="pa-3 pa-md-6">
                                <v-layout wrap justify-center>
                                    <v-responsive max-width="480" :aspect-ratio="1 / 1">
                                        <v-carousel height="auto" hide-delimiters show-arrows-on-hover :show-arrows="1 < giftcard.images.length">
                                            <v-carousel-item v-for="(image, index) in giftcard.images" :key="index">
                                                <v-img :src="image?.path || image?.src" width="100%" contain :aspect-ratio="1" />
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-responsive>
                                </v-layout>
                            </v-col>

                            <!-- 상품정보 -->
                            <v-col cols="12" md="5" class="pa-3 pa-md-6">
                                <v-responsive width="100%">
                                    <!-- 제품명 -->
                                    <template>
                                        <div class="headline primary--text">
                                            <p v-html="giftcard.name" />
                                        </div>
                                    </template>

                                    <v-divider class="my-5" />

                                    <!-- PC 수량 레이아웃 : 재고가 있을때 -->
                                    <template v-if="!$vuetify.breakpoint.mobile && carts.length">
                                        <div class="font-weight-medium"><span class="grey--text mr-2">01</span> 권종 및 수량</div>
                                        <cart-list v-model="carts" class="mt-5" />

                                        <v-divider class="my-5" />

                                        <div class="font-weight-medium"><span class="grey--text mr-2">02</span> 결제수단</div>
                                        <v-btn-toggle v-model="order.paymentMethod" mandatory color="primary" tile class="mt-5 paymentMethods">
                                            <v-btn v-if="paymentMethods.includes(`creditcard`)" outlined value="creditcard">
                                                <div>
                                                    <!-- <div class="primary--text font-size-14 mb-1">수수료 {{ giftcard.commission.creditcard }}%</div> -->
                                                    <div class="d-flex justify-space-between">
                                                        <div class="button-text font-size-14 font-size-md-16">카드결제</div>
                                                        <v-icon small>mdi-check</v-icon>
                                                    </div>
                                                </div>
                                            </v-btn>
                                            <v-btn v-if="paymentMethods.includes(`mobile`)" outlined value="mobile">
                                                <div>
                                                    <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.mobile }}%</div> -->
                                                    <div class="d-flex justify-space-between">
                                                        <div class="button-text font-size-14 font-size-md-16">휴대폰결제</div>
                                                        <v-icon small>mdi-check</v-icon>
                                                    </div>
                                                </div>
                                            </v-btn>
                                            <v-btn v-if="paymentMethods.includes(`simple`)" outlined value="simple">
                                                <div>
                                                    <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.simple }}%</div> -->
                                                    <div class="d-flex justify-space-between">
                                                        <div class="button-text font-size-14 font-size-md-16">간편결제</div>
                                                        <v-icon small>mdi-check</v-icon>
                                                    </div>
                                                </div>
                                            </v-btn>
                                            <v-btn v-if="paymentMethods.includes(`point`)" outlined value="point">
                                                <div>
                                                    <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.simple }}%</div> -->
                                                    <div class="d-flex justify-space-between">
                                                        <div class="button-text font-size-14 font-size-md-16">포인트결제</div>
                                                        <v-icon small>mdi-check</v-icon>
                                                    </div>
                                                </div>
                                            </v-btn>
                                        </v-btn-toggle>

                                        <v-sheet v-show="1 < paymentGateways.length" color="#F7F7F7" class="mt-2 pa-3">
                                            <v-layout mandatory color="primary">
                                                <v-radio-group v-model="order.paymentGateway" hide-details row class="mt-0 pt-0">
                                                    <template v-for="(paymentGateway, index) in paymentGateways">
                                                        <v-flex v-if="paymentGateway == `toss`" :key="index">
                                                            <v-radio hide-details label="토스결제" :value="paymentGateway"></v-radio>
                                                        </v-flex>
                                                        <v-flex v-else-if="paymentGateway == `danal`" :key="index">
                                                            <v-radio hide-details label="다날결제" :value="paymentGateway"></v-radio>
                                                        </v-flex>
                                                        <v-flex v-else-if="paymentGateway == `smartro`" :key="index">
                                                            <v-radio hide-details label="페이레터결제" :value="paymentGateway"></v-radio>
                                                        </v-flex>
                                                        <v-flex v-else :key="index">
                                                            <v-radio :label="paymentGateway" :value="paymentGateway"></v-radio>
                                                        </v-flex>
                                                    </template>
                                                </v-radio-group>
                                            </v-layout>
                                        </v-sheet>

                                        <template v-if="order.paymentMethod == `point`">
                                            <v-divider class="my-8" />
                                            <v-layout class="mt-4 align-center">
                                                <v-flex class="px-1"><v-text-field v-model="order.pointAmount" label="포인트 사용" maxlength="8" prepend-inner-icon="mdi-alpha-p-circle" dense outlined hide-details persistent-placeholder :readonly="!logon" /></v-flex>
                                                <v-flex shrink class="px-1 mb-2 mb-md-0"><v-btn color="primary" @click="setPoint">전액사용</v-btn></v-flex>
                                            </v-layout>
                                            <v-layout v-if="me" class="mt-1">
                                                <v-flex shrink class="px-1 mb-2 mb-md-0 caption"
                                                    >* <b>{{ me.name }}</b
                                                    >님 보유 포인트
                                                    <span class="primary--text"
                                                        ><b>{{ me.point.format() }}</b></span
                                                    >
                                                    <v-icon color="primary" size="20">mdi-alpha-p-circle</v-icon></v-flex
                                                >
                                            </v-layout>
                                        </template>

                                        <v-divider class="my-5" />

                                        <div class="font-weight-medium"><span class="grey--text mr-2">03</span> 쿠폰받을 휴대폰번호</div>
                                        <v-layout class="mt-5">
                                            <v-flex style="flex-grow: 0">
                                                <v-select v-model="order.receiver.phone1" :items="phones" :readonly="readonly" @click="onCertify()" outlined hide-details dense style="max-width: 120px" class="rounded-0" />
                                            </v-flex>
                                            <span class="mx-1"></span>
                                            <v-flex class="grow-1">
                                                <v-text-field v-model="order.receiver.phone2" label="-없이 숫자만 입력" :readonly="readonly" @click="onCertify()" outlined hide-details dense maxlength="8" @input="order.receiver.phone2 = order.receiver.phone2?.replace(/[^0-9]/g, '')" class="rounded-0" />
                                            </v-flex>
                                        </v-layout>
                                        <div class="mt-2">
                                            <div class="caption">* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.</div>
                                            <div class="caption">* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.</div>
                                        </div>

                                        <v-divider class="my-5" />

                                        <div class="font-weight-medium mb-5"><span class="grey--text mr-2">04</span> 금액 및 최종확인</div>

                                        <v-card color="grey lighten-4" elevation="0" class="rounded-0">
                                            <template v-for="(pickup, index) in order.pickups">
                                                <v-divider v-if="0 < index" :key="`d` + index" class="mx-5" />
                                                <v-card-text :key="index">
                                                    <v-layout>
                                                        <span>
                                                            <div>
                                                                {{ pickup.name }}<span class="grey--text"> X {{ pickup.quantity }}</span>
                                                            </div>
                                                        </span>
                                                        <v-spacer />
                                                        <span>{{ (pickup.salePrice * pickup.quantity).format() }}원</span>
                                                    </v-layout>
                                                </v-card-text>
                                            </template>
                                        </v-card>

                                        <v-card outlined elevation="0" class="mt-2 rounded-0">
                                            <v-card-text>
                                                <v-layout>
                                                    <span>결제수단</span>
                                                    <v-spacer />
                                                    <span v-if="order.paymentMethod == `creditcard`" class="grey--text text--darken-2">카드결제</span>
                                                    <span v-if="order.paymentMethod == `mobile`" class="grey--text text--darken-2">휴대폰결제</span>
                                                    <span v-if="order.paymentMethod == `simple`" class="grey--text text--darken-2">간편결제</span>
                                                </v-layout>
                                                <v-divider class="my-3" />
                                                <v-layout>
                                                    <span>결제 수수료</span>
                                                    <v-spacer />
                                                    <span class="grey--text text--darken-2">{{ commissionAmount.format() }}원</span>
                                                </v-layout>
                                                <v-divider class="my-3" />
                                                <v-layout>
                                                    <span>상품 합계 금액</span>
                                                    <v-spacer />
                                                    <span class="grey--text text--darken-2">{{ saleAmount.format() }}원</span>
                                                </v-layout>
                                            </v-card-text>
                                        </v-card>

                                        <v-layout class="mt-5">
                                            <v-flex grow-shrink-0>
                                                <span>최종 결제 금액</span>
                                            </v-flex>
                                            <v-spacer />
                                            <v-flex grow-shrink-0>
                                                <span class="primary--text d-flex align-center">
                                                    <b class="font-size-24 mr-1">{{ parseInt(totalAmount).format() }}</b> 원
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="giftcard.commission.mobile" class="pt-2">
                                            <v-flex grow-shrink-0>
                                                <span class="caption">소액결제시</span>
                                            </v-flex>
                                            <v-spacer />
                                            <v-flex grow-shrink-0>
                                                <span class="caption">
                                                    <b>{{ parseInt(totalAmount + commissionAmount).format() }}</b> 원
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout class="mt-5">
                                            <v-flex grow>
                                                <v-btn tile block dark x-large color="primary" elevation="0" @click="purchase()">구매하기</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-responsive>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-responsive>

            <!-- 인터섹팅 레이아웃 : 스크롤 아래로 내렸을때, 화면 상단에 도킹 -->
            <v-app-bar v-if="!isIntersecting" height="50" fixed color="white" elevation="1">
                <v-layout wrap justify-center>
                    <v-responsive width="100%" max-width="1200">
                        <v-tabs v-model="intersectingIndex" show-arrows grow class="pa-0">
                            <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-content">상품정보</v-btn></v-tab>
                            <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-information">구매정보</v-btn></v-tab>
                        </v-tabs>
                    </v-responsive>
                </v-layout>
            </v-app-bar>

            <!-- 상품 컨텐츠 -->
            <v-responsive>
                <v-card>
                    <v-tabs v-model="intersectingIndex" show-arrows grow class="pa-0">
                        <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-content">상품정보</v-btn></v-tab>
                        <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-information">구매정보</v-btn></v-tab>
                    </v-tabs>

                    <v-divider />

                    <div id="giftcard-content" class="mt-lg-4 pt-12"></div>

                    <v-responsive v-intersect="{ handler: onDetails, options: { threshold: [0, 1.0] } }">
                        <v-card-text class="mt-4">
                            <v-img src="/images/giftcards/gift-guide.jpg" />
                            <p v-html="giftcard?.content" class="pa-lg-4 img-products-views"></p>
                        </v-card-text>
                    </v-responsive>

                    <div id="giftcard-information" class="mt-lg-4 pt-lg-12"></div>
                    <v-responsive v-intersect="{ handler: onInformation, options: { threshold: [0, 1.0] } }" class="pa-3">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <caption class="caption_hidden" style="display: inline-block; margin: 10px; padding: 0px; box-sizing: inherit; font-size: 18px">
                                    교환/반품/품절안내
                                </caption>
                            </v-col>
                        </v-row>
                        <template v-for="(info, index) in setting.informations">
                            <v-row :key="index">
                                <v-col cols="12" md="4" class="grey lighten-3 d-flex align-center justify-center">
                                    <p v-html="info.term" class="text-center" />
                                </v-col>
                                <v-col cols="12" md="8">
                                    <p v-html="info.desc" />
                                </v-col>
                            </v-row>
                        </template>
                    </v-responsive>
                </v-card>
                <login-dialog ref="loginDialog" />
            </v-responsive>
        </template>

        <!-- Mobile 하단 네비게이션 -->
        <template v-if="$vuetify.breakpoint.mobile && ready" slot="fab" slot-scope="{ showScroll, scrollTo }">
            <v-app-bar app bottom height="auto" color="transparent" elevation="3">
                <v-responsive class="overflow-visible">
                    <!-- Top 버튼 -->
                    <!-- <v-btn v-show="showScroll" fab absolute top right x-large color="primary" class="mt-n14" @click="scrollTo(0,0)">
                    <div>
                        <v-icon class="mt-n2">{{ mdiChevronUp }}</v-icon>
                        <div class="body-2 mt-n1">top</div>
                    </div>
                </v-btn> -->

                    <v-card>
                        <!-- <v-layout justify-center>
                        <v-btn block slot="extension" @click="expand = !expand" text large height="32" :ripple="false" active-class="no-active">
                            <v-icon v-show="!expand">{{ mdiChevronUp }}</v-icon>
                            <v-icon v-show="expand">{{ mdiChevronDown }}</v-icon>
                        </v-btn>
                    </v-layout> -->
                        <v-expand-transition>
                            <v-responsive elevation="0" class="buy-fixed pa-4">
                                <div class="font-weight-medium"><span class="grey--text mr-2">01</span> 권종 및 수량</div>
                                <cart-list v-model="carts" class="mt-5" />

                                <v-divider class="my-5" />

                                <div class="font-weight-medium"><span class="grey--text mr-2">02</span> 결제수단</div>
                                <v-btn-toggle v-model="order.paymentMethod" mandatory color="primary" tile class="mt-5 paymentMethods">
                                    <v-btn v-if="paymentMethods.includes(`creditcard`)" outlined value="creditcard">
                                        <div>
                                            <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.creditcard }}%</div> -->
                                            <div class="d-flex justify-space-between">
                                                <div class="button-text font-size-14 font-size-md-16">카드결제</div>
                                                <v-icon small>mdi-check</v-icon>
                                            </div>
                                        </div>
                                    </v-btn>
                                    <v-btn v-if="paymentMethods.includes(`mobile`)" outlined value="mobile">
                                        <div>
                                            <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.mobile }}%</div> -->
                                            <div class="d-flex justify-space-between">
                                                <div class="button-text font-size-14 font-size-md-16">휴대폰결제</div>
                                                <v-icon small>mdi-check</v-icon>
                                            </div>
                                        </div>
                                    </v-btn>
                                    <v-btn v-if="paymentMethods.includes(`simple`)" outlined value="simple">
                                        <div>
                                            <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.simple }}%</div> -->
                                            <div class="d-flex justify-space-between">
                                                <div class="button-text font-size-14 font-size-md-16">간편결제</div>
                                                <v-icon small>mdi-check</v-icon>
                                            </div>
                                        </div>
                                    </v-btn>
                                    <v-btn v-if="paymentMethods.includes(`point`)" outlined value="point">
                                        <div>
                                            <!-- <div class="primary--text font-size-14 mb-2">수수료 {{ giftcard.commission.simple }}%</div> -->
                                            <div class="d-flex justify-space-between">
                                                <div class="button-text font-size-14 font-size-md-16">포인트결제</div>
                                                <v-icon small>mdi-check</v-icon>
                                            </div>
                                        </div>
                                    </v-btn>
                                </v-btn-toggle>

                                <v-sheet v-show="1 < paymentGateways.length" color="#F7F7F7" class="mt-2 pa-3">
                                    <v-layout mandatory color="primary">
                                        <v-radio-group hide-details v-model="order.paymentGateway" row>
                                            <template v-for="(paymentGateway, index) in paymentGateways">
                                                <v-flex v-if="paymentGateway == `toss`" :key="index">
                                                    <v-radio label="토스결제" outlined :value="paymentGateway"></v-radio>
                                                </v-flex>
                                                <v-flex v-else-if="paymentGateway == `danal`" :key="index">
                                                    <v-radio label="다날결제" outlined :value="paymentGateway"></v-radio>
                                                </v-flex>
                                                <v-flex v-else-if="paymentGateway == `smartro`" :key="index">
                                                    <v-radio label="페이레터결제" outlined :value="paymentGateway"></v-radio>
                                                </v-flex>
                                                <v-flex v-else :key="index">
                                                    <v-radio :label="paymentGateway" outlined :value="paymentGateway"></v-radio>
                                                </v-flex>
                                            </template>
                                        </v-radio-group>
                                    </v-layout>
                                </v-sheet>

                                <template v-if="order.paymentMethod == `point`">
                                    <v-divider class="my-8" />
                                    <v-layout class="mt-4 align-center">
                                        <v-flex class="px-1"><v-text-field v-model="order.pointAmount" label="포인트 사용" maxlength="8" prepend-inner-icon="mdi-alpha-p-circle" dense outlined hide-details persistent-placeholder :readonly="!logon" /></v-flex>
                                        <v-flex shrink class="px-1 mb-2 mb-md-0"><v-btn color="primary" @click="setPoint">전액사용</v-btn></v-flex>
                                    </v-layout>
                                    <v-layout v-if="me" class="mt-1">
                                        <v-flex shrink class="px-1 mb-2 mb-md-0 caption"
                                            >* <b>{{ me.name }}</b
                                            >님 보유 포인트
                                            <span class="primary--text"
                                                ><b>{{ me.point.format() }}</b></span
                                            >
                                            <v-icon color="primary" size="20">mdi-alpha-p-circle</v-icon></v-flex
                                        >
                                    </v-layout>
                                </template>

                                <v-divider class="my-5" />

                                <div class="font-weight-medium"><span class="grey--text mr-2">03</span> 쿠폰받을 휴대폰번호</div>
                                <v-layout class="mt-5">
                                    <v-flex style="flex-grow: 0">
                                        <v-select v-model="order.receiver.phone1" :items="phones" :readonly="readonly" @click="onCertify()" outlined hide-details dense style="max-width: 120px" class="rounded-0" />
                                    </v-flex>
                                    <span class="mx-1"></span>
                                    <v-flex class="grow-1">
                                        <v-text-field v-model="order.receiver.phone2" label="-없이 숫자만 입력" :readonly="readonly" @click="onCertify()" outlined hide-details dense maxlength="8" @input="order.receiver.phone2 = order.receiver.phone2?.replace(/[^0-9]/g, '')" class="rounded-0" />
                                    </v-flex>
                                </v-layout>
                                <div class="mt-2">
                                    <div class="caption">* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.</div>
                                    <div class="caption">* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.</div>
                                </div>

                                <v-divider class="my-5" />

                                <div class="font-weight-medium mb-5"><span class="grey--text mr-2">04</span> 금액 및 최종확인</div>

                                <v-card color="grey lighten-4" elevation="0" class="rounded-0">
                                    <template v-for="(pickup, index) in order.pickups">
                                        <v-divider v-if="0 < index" :key="`d` + index" class="mx-4" />
                                        <v-card-text :key="index">
                                            <v-layout>
                                                <span>
                                                    <div>
                                                        {{ pickup.name }}<span class="grey--text"> X {{ pickup.quantity }}</span>
                                                    </div>
                                                </span>
                                                <v-spacer />
                                                <span>{{ (pickup.salePrice * pickup.quantity).format() }}원</span>
                                            </v-layout>
                                        </v-card-text>
                                    </template>
                                </v-card>

                                <v-card outlined elevation="0" class="mt-2 rounded-0">
                                    <v-card-text>
                                        <v-layout>
                                            <span>결제수단</span>
                                            <v-spacer />
                                            <span v-if="order.paymentMethod == `creditcard`" class="grey--text text--darken-2">카드결제</span>
                                            <span v-if="order.paymentMethod == `mobile`" class="grey--text text--darken-2">휴대폰결제</span>
                                            <span v-if="order.paymentMethod == `simple`" class="grey--text text--darken-2">간편결제</span>
                                        </v-layout>
                                        <v-divider class="my-3" />
                                        <v-layout>
                                            <span>결제 수수료</span>
                                            <v-spacer />
                                            <span class="grey--text text--darken-2">{{ commissionAmount.format() }}원</span>
                                        </v-layout>
                                        <v-divider class="my-3" />
                                        <v-layout>
                                            <span>상품 합계 금액</span>
                                            <v-spacer />
                                            <span class="grey--text text--darken-2">{{ saleAmount.format() }}원</span>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>

                                <v-layout class="mt-5">
                                    <v-flex grow-shrink-0>
                                        <span>최종 결제 금액</span>
                                    </v-flex>
                                    <v-spacer />
                                    <v-flex grow-shrink-0>
                                        <span class="primary--text d-flex align-center">
                                            <b class="font-size-24 mr-1">{{ parseInt(totalAmount).format() }}</b> 원
                                        </span>
                                    </v-flex>
                                </v-layout>
                                <v-layout v-if="giftcard.commission.mobile" class="pt-2">
                                    <v-flex grow-shrink-0>
                                        <span class="caption">소액결제시</span>
                                    </v-flex>
                                    <v-spacer />
                                    <v-flex grow-shrink-0>
                                        <span class="caption">
                                            <b>{{ parseInt(totalAmount + commissionAmount).format() }}</b> 원
                                        </span>
                                    </v-flex>
                                </v-layout>
                            </v-responsive>
                            <!-- <v-responsive v-show="expand" elevation="0"> -->

                            <!-- PC 수량 레이아웃 : 옵션상품 사용할때-->
                            <!-- <template v-if="giftcard.optionalProducts?.length">
                                <v-divider class="mb-4"/>
                                <optional-giftcards v-model="carts" :giftcard="giftcard" class="px-4" />
                            </template>

                            <template v-if="carts.length">
                                <v-divider class="my-4"/>
                                <cart-list v-model="carts" class="px-4"/>
                            </template>

                            <v-divider class="my-4"/>
                            <v-layout class="px-4 mb-4">
                                <v-flex grow-shrink-0>
                                    <span class="subtitle-2">총 합계</span>
                                </v-flex>
                                <v-spacer />
                                <v-flex grow-shrink-0>
                                    <span class="subtitle-2"><b class="primary--text">{{ parseInt(totalAmount).format() }}</b> 원</span>
                                </v-flex>
                            </v-layout> -->
                            <!-- </v-responsive> -->
                        </v-expand-transition>

                        <v-divider />

                        <v-card-actions>
                            <v-layout class="py-1">
                                <!-- <v-flex grow-shrink-0 class="px-1">
                                <v-btn color="primary" min-width="auto" x-large class="px-4" outlined @click="addToCart"><v-icon>{{ }</v-icon></v-btn>
                            </v-flex>
                            <v-flex grow-shrink-0 class="px-1">
                                <v-btn color="primary" min-width="auto" x-large class="px-4" outlined @click="gift"><v-icon>{{ }</v-icon></v-btn>
                            </v-flex> -->
                                <v-flex class="px-1">
                                    <v-btn block color="primary" x-large @click="purchase()"><span class="subtitle-2 white--text">구매하기</span></v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-actions>
                    </v-card>
                </v-responsive>

                <!-- 본인인증 -->
            </v-app-bar>
        </template>

        <kcp-cert ref="kcpCert" @input="onVerify" />
        <payment-gateway ref="paymentGateway" @complete="complete" />
    </component>
</template>
<script>
import api from "@/api";
import { mapState } from "vuex";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import LoginDialog from "@/components/client/login/login-dialog.vue";
import GiftcardInfoField from "@/components/client/gshop/giftcard/product-info-field.vue";
import CartList from "@/components/client/gshop/giftcard/cart-list.vue";

// PG
import PaymentGateway from "@/components/client/gshop/payment-gateway.vue";

export default {
    components: {
        KcpCert,
        LoginDialog,
        GiftcardInfoField,
        CartList,

        PaymentGateway,
    },
    data() {
        return {
            ready: false,
            expand: false, // 모바일 하단 네비게이션 확장·축소

            giftcard: null,
            prevProduct: null,
            nextProduct: null,

            carts: [], // 수량

            setting: {
                informations: [],
            },

            me: null,

            order: {
                _id: null,
                pickups: [],

                buyer: {
                    _id: null,
                    name: null,
                    username: null,
                    phone: null,
                    email: null,
                    birthday: null,
                },

                receiver: {
                    name: null,
                    phone: null,
                    phone1: "010",
                    phone2: "",
                    email: null,
                },

                shippingAddress: {
                    postcode: null,
                    address1: null,
                    address2: null,
                },

                demand: "",

                pointAmount: 0,
                cashAmount: 0,

                paymentGateway: null,
                paymentMethod: "creditcard",
                isCollect: false,
                telecom: null,
                authNumber: null,
            },

            // 인터렉션 & 인터섹팅 데이터
            intersectingIndex: 0,
            intersecting: {
                isDetails: false,
                isInformation: false,
            },

            offsetTop: 0,
            showAppBar: false,
            isIntersecting: false,
            isScroll: false,

            phones: ["010", "011", "016", "017", "018", "019"],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            this.order.pickups = this.carts = [];

            let { giftcard } = await api.v1.gshop.giftcards.get({ _id: this.$attrs._giftcard });
            this.giftcard = giftcard;

            for (let option of giftcard?.options || []) {
                this.carts.push({
                    _giftcard: giftcard._id,
                    _option: option._id,
                    giftcard,
                    name: option.name,
                    code: option.code || giftcard.code,
                    type: option.type,
                    thumb: giftcard.thumb,
                    platform: option.platform,
                    saleAmount: option.saleAmount,
                    salePrice: option.salePrice,
                    stock: option.stock,
                    soldout: option.soldout,
                    quantity: 0,
                });
            }

            if (this.accessToken) {
                let { me } = await api.v1.me.profile.get();
                let { _id, name, username, phone, email, postcode, address1, address2, birthday } = me;
                let [phone1, phone2, phone3] = phone?.phoneNumberFormat?.()?.split?.("-") || [];

                this.me = me;

                this.$set(this.order, "buyer", {
                    ...this.order.buyer,
                    _id,
                    name,
                    username,
                    phone,
                    phone1,
                    phone2: phone2 + phone3,
                    email,
                    birthday,
                });

                if (me.orderCertType != `비인증`) {
                    this.$set(this.order, "receiver", {
                        name,
                        phone1,
                        phone2: phone2 + phone3,
                        birthday,
                    });
                }
            }

            let { setting } = await api.v1.gshop.setting.get();
            this.setting = setting;

            this.order.paymentMethod = this.paymentMethods[0];
            this.order.paymentGateway = this.paymentGateways[0];

            this.ready = true;
        },

        // 선물·구매·장바구니 클릭했을때, 검증
        validate() {
            try {
                if (!this.carts.find((cart) => 0 < cart.quantity)) throw new Error("구매수량을 입력해주세요.");
                if (this.carts.find((cart) => cart.soldout)) throw new Error("품절 처리된 상품 입니다.");
                if (!this.order.receiver.phone1 || !this.order.receiver.phone2) throw new Error("쿠폰받을 휴대폰 번호를 확인해주세요");
                this.order.receiver.phone = this.order.receiver.phone1 + this.order.receiver.phone2;

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },

        async login() {
            return new Promise((resolve, reject) => {
                if (this.logon) resolve();
                else {
                    this.$refs.loginDialog.$off();
                    this.$refs.loginDialog.$on("login", async () => {
                        let { me } = await api.v1.me.profile.get();
                        let { _id, name, username, phone, email, postcode, address1, address2, birthday } = me;
                        let [phone1, phone2, phone3] = phone?.phoneNumberFormat?.()?.split?.("-") || [];

                        this.me = me;

                        this.$set(this.order, "buyer", {
                            ...this.order.buyer,
                            _id,
                            name,
                            username,
                            phone,
                            phone1,
                            phone2: phone2 + phone3,
                            email,
                            birthday,
                        });

                        if (me.orderCertType != `비인증`) {
                            this.$set(this.order, "receiver", {
                                name,
                                phone1,
                                phone2: phone2 + phone3,
                                birthday,
                            });
                        }
                        resolve();
                    });
                    this.$refs.loginDialog.$on("cancel", (_) => reject());
                    this.$refs.loginDialog.open();
                }
            });
        },
        async purchase() {
            if (this.validate()) {
                if (this.siteInfo.memberPayable && !this.siteInfo.nonmemberPayable) await this.login();
                this.pay();
            }
        },
        async pay() {
            if (this.validate()) {
                let { order } = await api.v1.gshop.orders.post(this.order);

                switch (order.paymentMethod) {
                    case "creditcard":
                    case "mobile":
                    case "simple": {
                        this.$refs.paymentGateway.order = order;
                        this.$refs.paymentGateway.pay();
                        break;
                    }
                    case "point": {
                        let { payload } = await api.v1.gshop.orders.pay.post(order);
                        this.complete(payload);
                        break;
                    }
                }
            }
        },
        complete(payload) {
            this.$router.push({ path: `/gshop/result`, query: payload });
        },
        // 본인인증 완료시, 데이터 바인딩
        onVerify({ name, phone, birthday }) {
            let [phone1, phone2, phone3] = String(phone).phoneNumberFormat().split("-");

            this.order.receiver.name = name;
            this.order.receiver.phone1 = phone1;
            this.order.receiver.phone2 = phone2 + phone3;
            this.order.receiver.birthday = birthday;
        },
        async onCertify() {
            if (this.siteInfo.nonmemberPayable) this.$refs.kepCert.open();
            if (this.siteInfo.memberPayable) {
                if (this.logoff) {
                    await this.login();
                }

                if (this.me?.orderCertType == "인증") this.$refs.kcpCert.open();
            }
        },
        setPoint() {
            this.order.pointAmount = this.totalAmount < this.me.point ? this.totalAmount : this.me.point;
        },

        ///////////////////////////////////////////////////////////////////////////////////
        // 인터렉션
        ///////////////////////////////////////////////////////////////////////////////////
        scrollTop() {
            window.scrollTo(0, 0);
        },

        onIntersect(entries, observer) {
            if (this.isIntersecting != entries[0].isIntersecting) this.isIntersecting = entries[0].isIntersecting;
        },
        onDetails(entries, observer) {
            if (this.intersecting.isDetails != entries[0].isIntersecting) this.intersecting.isDetails = entries[0].isIntersecting;
        },
        onInformation(entries, observer) {
            if (this.intersecting.isInformation != entries[0].isIntersecting) this.intersecting.isInformation = entries[0].isIntersecting;
        },
        // onScroll (entries, observer) {
        //     // More information about these options
        //     // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        //     this.isScroll = entries[0].isIntersecting;
        // },
    },
    computed: {
        siteInfo() {
            return this.$store.state.siteInfo;
        },
        template() {
            return () => import(`@/templates/${this.$theme.pages.gshop}`);
        },
        logon() {
            return !!this.$store.state.accessToken;
        },
        logoff() {
            return !this.$store.state.accessToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },

        saleAmount() {
            return this.carts.reduce((saleAmount, cart) => {
                return (saleAmount += cart.salePrice * cart.quantity);
            }, 0);
        },

        cutUnit() {
            return this.giftcard.commission?.cutUnit || 1;
        },
        commission() {
            return this.giftcard.commission?.[this.order.paymentMethod] || 0;
        },
        commissionAmount() {
            return (
                this.carts.reduce((commissionAmount, cart) => {
                    commissionAmount += Math.floor((cart.salePrice * this.commission) / (1000 * this.cutUnit)) * cart.quantity * this.cutUnit * 10;
                    return commissionAmount;
                }, 0) +
                this.carts.reduce((commissionAmount, cart) => {
                    commissionAmount += Math.floor((cart.salePrice * this.paymentCommission) / (1000 * this.cutUnit)) * cart.quantity * this.cutUnit * 10;
                    return commissionAmount;
                }, 0)
            );
        },
        totalAmount() {
            return this.saleAmount + this.commissionAmount;
        },
        creditcardEnabled(){
            if (this.giftcard.payments.filter(payment => payment.method == "creditcard" && payment.disabled).length == this.siteInfo.paymentMethods.filter(paymentMethod => paymentMethod == "creditcard").length) return false;
            if (this.siteInfo.paymentMethods.includes(`creditcard`)) return true;
            return false;
        },
        mobileEnabled(){
            if (this.giftcard.payments.filter(payment => payment.method == "mobile" && payment.disabled).length == this.siteInfo.paymentMethods.filter(paymentMethod => paymentMethod == "mobile").length) return false;
            if (this.siteInfo.paymentMethods.includes(`mobile`)) return true;
            return false;
        },
        simpleEnabled(){
            if (this.giftcard.payments.filter(payment => payment.method == "simple" && payment.disabled).length == this.siteInfo.paymentMethods.filter(paymentMethod => paymentMethod == "simple").length) return false;
            if (this.siteInfo.paymentMethods.includes(`simple`)) return true;
            return false;
        },
        pointEnabled() {
            return this.giftcard?.paymentMethods?.includes?.("point");
        },
        paymentMethods() {
            var paymentMethods = [];
            if (this.creditcardEnabled) paymentMethods.push(`creditcard`);
            if (this.mobileEnabled) paymentMethods.push(`mobile`);
            if (this.simpleEnabled) paymentMethods.push(`simple`);
            if (this.pointEnabled) paymentMethods.push(`point`);
            return paymentMethods;
        },
        paymentGateways() {
            var paymentGateways = [];
            for (var index in this.siteInfo.paymentMethods) {
                if (this.siteInfo.paymentMethods[index] == this.order.paymentMethod && !this.giftcard.payments.find(payment => payment.gateway == this.siteInfo.paymentGateways[index] && payment.method == this.order.paymentMethod)?.disabled) paymentGateways.push(this.siteInfo.paymentGateways[index]);
            }
            return paymentGateways;
        },
        paymentCommissions() {
            var paymentCommissions = [];
            for (var index in this.siteInfo.paymentMethods) {
                if (this.siteInfo.paymentMethods[index] == this.order.paymentMethod && !this.giftcard.payments.find(payment => payment.gateway == this.siteInfo.paymentGateways[index] && payment.method == this.order.paymentMethod)?.disabled) paymentCommissions.push(this.siteInfo.paymentCommissions[index]);
            }
            return paymentCommissions;
        },
        paymentCommission() {
            return this.paymentCommissions[this.paymentGateways.findIndex((paymentGateway) => paymentGateway == this.order.paymentGateway)] || 0;
        },

        readonly() {
            if (this.siteInfo.nonmemberPayable) return true;
            if (this.siteInfo.memberPayable) {
                if (this.logoff) return true;
                if (this.me?.orderCertType == `고정`) return true;
                if (this.me?.orderCertType == `인증`) return true;
            }
            return false;
        },

    },
    watch: {
        quantity() {
            this.saleAmount = this.quantity * this.salePrice;
        },
        async accessToken() {
            if (this.accessToken) {
                let { me } = await api.v1.me.profile.get();
                this.me = me;
            } else this.me = null;
        },

        carts: {
            handler() {
                for (let cart of this.carts) {
                    let { platform, stock } = cart;
                    let quantity = parseInt(String(cart.quantity).replace(/[^0-9\-]/g, "") || 0);

                    if (this.siteInfo.orderType == `immediately` && [`excel`, `nanuri-excel`].includes(platform) && stock < quantity) {
                        alert("재고가 부족합니다");
                        cart.quantity = stock;
                    } else if (quantity < 0) cart.quantity = 0;
                    else cart.quantity = quantity;

                    this.order.pickups = this.carts.filter((cart) => 0 < cart.quantity);
                }
            },
            deep: true,
        },

        // $route: {
        //     deep: true,
        //     handler(){
        //         this.ready = false;
        //         this.init();
        //     }
        // },

        "order.paymentMethod"() {
            this.$nextTick(() => {
                this.order.paymentGateway = this.paymentGateways[0];
            });
        },

        "$route.params._giftcard"() {
            this.ready = false;
            this.init();
        },

        intersecting: {
            deep: true,
            handler() {
                if (this.intersecting.isDetails) this.intersectingIndex = 0;
                else if (this.intersecting.isInformation) this.intersectingIndex = 1;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.table_detail_guide th,
.table_detail_guide td {
    padding: 12px !important;
}

@media (max-width: 768px) {
    .table_detail_guide {
        display: block;
    }
    .table_detail_guide tr,
    .table_detail_guide th,
    .table_detail_guide td {
        display: block;
    }
    .table_detail_guide th,
    .table_detail_guide td {
        padding: 0 !important;
    }
    .table_detail_guide tr {
        margin-bottom: 8px !important;
    }
}

@media (max-width: 1024px) {
    .buy-fixed {
        max-height: 200px;
        overflow-y: auto;
    }
}

.v-app-bar {
    height: auto !important;
}
.v-app-bar >>> .v-toolbar__content {
    padding: 0 !important;
    height: auto !important;
}
.v-number-field >>> input {
    text-align: center !important;
}
.v-btn--active.no-active::before {
    opacity: 0 !important;
}
.v-card__text {
    color: black !important;
}
.giftcard-view .giftcard-name {
    font-size: 32px;
    font-weight: 500;
}
.giftcard-view .giftcard-description {
    font-size: 13px;
}
.giftcard-view .giftcard-content >>> img {
    max-width: 100%;
    height: auto;
}
.giftcard-view .giftcard-notification >>> img {
    max-width: 100%;
    height: auto;
}
.giftcard-view >>> input {
    text-align: end;
}

.img-products-views >>> img {
    max-width: 100%;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group).paymentMethods {
    width: 100%;
    flex-wrap: wrap;
    > .v-btn {
        width: calc(100% / 3 - 24px / 3);
        height: 50px !important;
        border: 1px solid !important;
        justify-content: flex-start;
        text-align: start;
        padding: 0 20px;
        &:not(.v-btn--active) {
            color: #999;
            border-color: #ececec;
        }
        .v-icon {
            color: #999;
        }
        &--active {
            border-color: currentColor !important;
            .button-text {
                color: #111;
            }
            &::before {
                opacity: 0.1;
            }
            .v-icon {
                color: #111;
            }
        }
        ::v-deep {
            .v-btn__content {
                display: block;
                width: 100%;
            }
        }
        &:nth-child(2) {
            margin: 0 12px;
        }
    }
}
::v-deep {
    .font-size {
        &-14 {
            font-size: 14px;
        }
        &-16 {
            font-size: 16px;
        }
        &-24 {
            font-size: 24px;
        }
    }
}
@media (max-width: 1024px) {
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group).paymentMethods {
        > .v-btn {
            height: 40px !important;
            padding: 0 6px;
        }
    }
}

@media (min-width: 768px) {
    ::v-deep {
        .font-size-md {
            &-14 {
                font-size: 14px;
            }
            &-16 {
                font-size: 16px;
            }
            &-24 {
                font-size: 24px;
            }
        }
    }
}
</style>